import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import '../Css/all_style.css';
import './servicesection.css';

const data = {
    "cst": {
        "btnLabel": "Continuous Security Testing",
        "title": "Continuous Security Testing",
        "p1": "Safeguard your digital assets with our continuous, proactive security testing with expert-led assessments—ensuring your systems are always protected.",
        "p2": "",
        "subTitle1": "Enhanced Vulnerability Analysis",
        "desc1": "Move beyond basic detection with expert-led assessments that validate vulnerabilities and prioritize remediation efforts.",
        "subTitle2": "Deeper Security Insights",
        "desc2": "Gain a comprehensive understanding of your security posture with in-depth testing and analysis, empowering informed decision-making.",
        "image": "cst.svg",
    },
    "ast": {
        "btnLabel": "Application Security Testing",
        "title": "Application Security Testing",
        "p1": "Uncover and Address Application Vulnerabilities",
        "p2": "Secure your applications with comprehensive security assessments through our Application Security Testing (AST) service. We help identify vulnerabilities and weaknesses within your application code, ensuring they are addressed before attackers can exploit them.",
        "subTitle1": "Reduced Application Risk",
        "desc1": "Reduced Application Risk: Minimize the risk of application breaches by proactively identifying and remediating vulnerabilities early in the development lifecycle.",
        "subTitle2": "Improved Security Posture",
        "desc2": "Improved Security Posture: Strengthen your overall security posture by ensuring your applications are built with security in mind, reducing the attack surface for malicious actors.",
        "image": "appsec.svg",
    },
    "ia": {
        "btnLabel": "Infrastructure Assessment",
        "title": "Infrastructure Assessment",
        "p1": "Uncover Hidden Risks: Secure Your Infrastructure with In-Depth Assessments",
        "p2": "Secure your digital foundation with a comprehensive Infrastructure Security Assessment from SecureNexus. Our in-depth assessments go beyond the surface, meticulously evaluating your IT infrastructure for vulnerabilities, misconfigurations, and security gaps. This proactive approach identifies potential weaknesses before they can be compromised, ensuring the resilience of your infrastructure and the security of your data.",
        "subTitle1": "Proactive Threat Detection",
        "desc1": "Identify and address vulnerabilities in your IT infrastructure before attackers can exploit them.",
        "subTitle2": "Enhanced Security Posture",
        "desc2": "Gain a deeper understanding of your security posture and prioritize remediation efforts for the most critical issues.",
        "image": "infra.svg",
    },
    "csa": {
        "btnLabel": "Cloud Security Assessment",
        "title": "Cloud Security Assessment",
        "p1": "Navigate the Cloud Landscape with Confidence: Secure Your Cloud Environment",
        "p2": "SecureNexus Cloud Security Assessments provide a comprehensive evaluation of your cloud environment, identifying vulnerabilities, misconfigurations, and security gaps specific to cloud platforms.  Our assessments leverage expert knowledge and industry best practices to ensure your cloud security posture aligns with industry regulations and compliance requirements.",
        "subTitle1": "Enhanced Cloud Security",
        "desc1": "Minimize cloud-based risks by identifying and addressing vulnerabilities before they can be exploited by attackers.",
        "subTitle2": "Compliance Confidence",
        "desc2": "Ensure your cloud environment adheres to industry regulations and compliance standards with a comprehensive security assessment.",
        "image": "cloud.svg",
    },
    "tm": {
        "btnLabel": "Threat Modeling",
        "title": "Threat Modeling",
        "p1": "Collaboratively Identify and Mitigate Threats Before They Emerge",
        "p2": "SecureNexus Threat Modeling goes beyond reactive security measures. We work collaboratively with your team to proactively identify potential threats and vulnerabilities early in the development lifecycle. Through structured workshops and expert guidance, we map out attack vectors and assess potential risks, allowing you to build security directly into the design of your systems and applications.",
        "subTitle1": "Reduced Development Costs",
        "desc1": "Identify and address security issues early in the development process, avoiding costly rework and delays.",
        "subTitle2": "Enhanced Security Posture",
        "desc2": "Proactively mitigate threats by building security considerations into the foundation of your applications from the start.",
        "image": "tm.svg",
    },
    "scr": {
        "btnLabel": "Source Code Review",
        "title": "Source Code Review",
        "p1": "Unveil Hidden Vulnerabilities and Secure Your Applications",
        "p2": "SecureNexus Source Code Review offers a meticulous analysis of your codebase, conducted by experienced security professionals. We go beyond basic syntax checks to identify and address security flaws, potential vulnerabilities, and coding practices that could lead to breaches. This proactive approach ensures your applications are built with security in mind, minimizing risks and safeguarding your sensitive data.",
        "subTitle1": "Reduced Application Risk",
        "desc1": "Minimize the chance of security vulnerabilities by proactively identifying and remediating flaws in your code before deployment.",
        "subTitle2": "Improved Development Efficiency",
        "desc2": "Catch security issues early in the development lifecycle, avoiding costly rework and delays down the line.",
        "image": "code_review.svg",
    },
    "rt": {
        "btnLabel": "Red Teaming",
        "title": "Red Teaming",
        "p1": "Put Your Security to Test with Simulated Attacks",
        "p2": "SecureNexus Red Teaming goes beyond traditional security assessments. We simulate real-world attack scenarios, acting as a professional adversary to uncover vulnerabilities and evaluate the effectiveness of your security posture.  This proactive approach exposes potential weaknesses before attackers can exploit them, allowing you to remediate critical issues and strengthen your defenses.",
        "subTitle1": "Uncover Hidden Weaknesses",
        "desc1": "Identify vulnerabilities and misconfigurations in your security posture that traditional assessments might miss.",
        "subTitle2": "Test Your Security Readiness",
        "desc2": "Evaluate the effectiveness of your security controls and incident response plans in a controlled environment.",
        "image": "red_teaming.svg",
    },
    "ca": {
        "btnLabel": "Cybersecurity Advisory",
        "title": "Cybersecurity Advisory",
        "p1": "Navigate the Evolving Threat Landscape with Expert Guidance",
        "p2": "SecureNexus Cybersecurity Advisory provides strategic guidance and expert insights to help you navigate the ever-evolving cybersecurity landscape. Our team of experienced security professionals offers tailored advice on security strategy development, risk management, compliance best practices, and incident response planning. We work collaboratively with your team to identify your unique security needs and develop a comprehensive strategy to protect your digital assets and data.",
        "subTitle1": "Proactive Security Strategy",
        "desc1": "Develop a customized security strategy that aligns with your specific business goals and risk tolerance.",
        "subTitle2": "Expert Guidance",
        "desc2": "Leverage the knowledge and experience of our security professionals to make informed decisions for your security posture.",
        "image": "Group.svg",
    },
};

const ServicesDetails = ({withNav, keyname}) => {
    const dataObj = data[keyname];

    const divClassName = withNav ? 'services-detail' : 'services-detail services-detail-only'

    return (
        <div id={`service_${keyname}`} className="services-content">
            <div className={`${divClassName}`} style={{ marginBottom:'30px'}}>
                <h2>{dataObj.title}</h2>
                <p>{dataObj.p1}</p>
                <p>{dataObj.p2}</p>
            
                {/* <!-- New subsection for image and text --> */}
                <div className="services-subsection">
                    <div className="subsection-text">
                        <ul>
                            <li><h3>{dataObj.subTitle1}</h3></li>
                            <p>{dataObj.desc1}</p>
                            <li><h3>{dataObj.subTitle2}</h3></li>
                            <p>{dataObj.desc2}</p>
                            {/* <button className="use-case-btn">Know More</button> */}
                        </ul>
                    </div>
                    <div className="subsection-image">
                        <img src={`img/Services page/${dataObj.image}`} alt="Subsection Image"/>
                    </div>
                </div>
            </div> 
        </div>
    );
}

const NavButton = ({ label, isSelected, onClick }) => {
    return (
      <button
        className={`nav-button ${isSelected ? 'selected' : ''}`}
        onClick={onClick}
      >
        {label}
      </button>
    );
  };

const Servicesection = () => {
    const [selectedButton, setSelectedButton] = useState('');

    const buttons = [
        'Continuous Security Testing', 'Application Security Testing', 'Infrastructure Assessment',
        'Cloud Security Assessment', 'Threat Modeling', 'Source Code Review',
        'Red Teaming', 'Cybersecurity Advisory'
    ];

    function scrollToServiceDiv(elementId) {
        const element = document.getElementById(`service_${elementId}`);
        if (element) {
            let dims = element.getBoundingClientRect();
            var x = dims.left;
            var y = dims.top;
            window.scrollTo(window.scrollX + x, window.scrollY + y - 90);
        }
    }

    const location = useLocation();
     useEffect(() => {
        const btnId = (location.hash).replace('#', '');
        setSelectedButton(btnId);
    }, [location]);

    useEffect(() => {
        scrollToServiceDiv(selectedButton);
    }, [selectedButton]);

    return (
        <div className="services-section">
            <div className="services-intro">
                <h1>Our Services</h1>
                <p>In the ever-evolving cybersecurity landscape, staying ahead of threats requires a comprehensive strategy. SecureNexus offers a full suite of security services designed to empower your organization with the tools and expertise needed to defend your digital assets, navigate risks, and achieve a proactive security posture.</p>
            </div>
        
            <div className="nav-bar">
                {/* {buttons.map((button) => ( */}
                {Object.keys(data).map(key => (
                    <NavButton
                        key={key}
                        label={data[key].btnLabel}
                        isSelected={selectedButton === key}
                        onClick={() => {
                                setSelectedButton(key);
                            }
                        }
                    />
                ))}
                {/* ))} */}
            </div>

            <br></br><br></br>
            
            <div>
                {Object.keys(data).map(key => (
                   <ServicesDetails keyname={key} />
                ))}
            </div>
            {/* <div id="cst">
                <div className="services-content">
                    <ServicesDetails keyname="cst" />
                </div>
            </div>

            <div id="ast">
                <div className="services-content">
                    <ServicesDetails keyname="ast" />
                </div>
            </div>
            <div id="ia">
                <div className="services-content">
                    <ServicesDetails keyname="ia" />
                </div>
            </div>
            <div id="csa">
                <div className="services-content">
                    <ServicesDetails keyname="csa" />
                </div>
            </div>
                
            <div id="tm">
                <div className="services-content">
                    <ServicesDetails keyname="tm" />
                </div>
            </div>

            <div id="scr">
                <div className="services-content">
                    <ServicesDetails keyname="scr" />
                </div>
            </div>

            <div id="rt">
                <div className="services-content">
                    <ServicesDetails keyname="rt" />
                </div>
            </div>

            <div id="ca">
                <div className="services-content">
                    <ServicesDetails keyname="ca" />
                </div>
            </div> */}
        </div>
    );
}

export default Servicesection;